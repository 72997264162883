import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';

import HeroSpace from '../../components/HeroSpace/HeroSpace';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import QuoteBox from '../../components/QuoteBox/QuoteBox';

import { combine, txt, bg, flex } from '../../style/utils';
import { getImage } from '../../utils/queryUtils';

import styles from './case-study.module.scss';

import bulletPoint from '../../../assets/icons/bulletpoint.png';
import geography from '../../../assets/icons/geography.png';
import time from '../../../assets/icons/time.png';
import screens from '../../../assets/icons/screens.png';

const VodafoneCaseStudy = ({ data }) => {
  const topImages = [
    {
      image: getImage(data.topImages, 'vodafone1.jpg'),
      alt: 'Digital signs in a retail store',
    },
    {
      image: getImage(data.topImages, 'vodafone2.jpg'),
      alt: 'Digital sign at the entrance to a retail store',
    },
    {
      image: getImage(data.topImages, 'vodafone3.jpg'),
      alt: 'Digital signs in a kiosk at a shopping centre',
    },
  ];
  return (
    <div>
      <Helmet
        title="Case study: Vodafone Internal Communications"
        description="Find out how Vodafone have used Flare digital signage to create a powerful global internal communications channel"
      />
      <HeroSpace
        title="Case Study"
        subtitle="Flare for Vodafone's global internal communications."
        image={{
          fluid: data.heroImage.fluid,
          alt: 'Line drawing of an cityscape',
          className: styles.heroGraphic,
        }}
        extraChildren={
          <div className={flex.col}>
            <div
              className={combine(
                flex.jcc,
                flex.row,
                styles.summary,
                styles.colMobile
              )}
            >
              <div className={combine(flex[1], styles.blurbContainer)}>
                <h4 className={txt.purple}>
                  How Flare&apos;s connecting Vodafone teams around the world
                </h4>
                <p className={combine(txt.darkGrey)}>
                  Mobile telecoms giant Vodafone has seen rapid growth, both
                  organically and through a series of acquisitions and mergers.
                  With more than 100,000 employees in over 20 markets to
                  communicate with, they needed to think outside of the box.{' '}
                </p>
              </div>
              <div className={combine(flex[1], styles.quoteContainer)}>
                <QuoteBox>This is a quote!</QuoteBox>
              </div>
            </div>
            <div
              className={combine(
                styles.imageContainer,
                flex.row,
                flex.jcc,
                flex.aic,
                styles.colMobile
              )}
            >
              {topImages.map(({ image, alt }, i) => (
                <Img className={styles.topImage} key={i} {...image} alt={alt} />
              ))}
            </div>
          </div>
        }
      />

      <ContentWrapper
        backgroundClasses={combine(bg.white)}
        variant={['narrow', 'reducedPadding']}
      >
        <h5>The challenge</h5>
        <div className={combine(flex.row, styles.colMobile)}>
          <div className={combine(flex[1], styles.mainSectionCol)}>
            <p>
              Vodafone needed an internal communications channel that could be
              used with their existing screens, regardless of the network
              architectures, that was easy to use and simple to roll out.
            </p>
            <p>
              Whilst they needed to roll out the ability to create local
              communications, it was important that the look and feel of the
              brand could still be managed.
            </p>
            <p>
              The system needed to be controlled centrally, scalable, secure and
              enterprise ready.
            </p>
          </div>
          <div className={combine(flex[1], styles.mainSectionCol)}>
            <p>By implementing Flare at Vodafone we delivered: </p>
            <ul style={{ listStyleImage: `url(${bulletPoint}) ` }}>
              <li>Easy to manage and easy to deploy digital signage.</li>
              <li>
                Control over their branding and the look and feel of their
                signage at sites around the world.
              </li>
              <li>The power to create communications locally.</li>
              <li>A global channel for important announcements.</li>
              <li>Enhanced awareness of customer feedback among staff. </li>
              <li>
                Improved engagement with staff on social platforms and the
                intranet from digital pointer communications.
              </li>
            </ul>
          </div>
        </div>
        <Img
          fluid={data.bigImage.fluid}
          alt="Digital signs in a Vodafone retail store"
        />
      </ContentWrapper>
      <ContentWrapper
        backgroundClasses={combine(bg.white)}
        variant={['narrow', 'reducedPadding']}
      >
        <h5>How we did it</h5>
        <div className={combine(flex.row, styles.colMobile)}>
          <div className={combine(flex[3], styles.mainSectionCol)}>
            <p>
              We delivered 1,300 devices with Flare pre-installed, creating a
              connected network of digital screens across the Vodafone business.
            </p>
            <p>
              The rollout took just 9 months from start to finish. We integrated
              it with Vodafone&apos;s NPS platform to deliver real-time customer
              information.
            </p>
          </div>
          <div className={combine(flex[4], styles.mainSectionCol)}>
            <p>
              It was essential that the communications team were able to retain
              control of their message branding, so we created branded message
              templates that local users could choose from when creating their
              communications.
            </p>
            <p>
              This meant that communications could be created locally in
              accordance with the site&apos;s needs and requirements, without
              compromising their all-important brand image.
            </p>
          </div>
        </div>
      </ContentWrapper>
      <ContentWrapper
        backgroundClasses={combine(bg.lightBlue)}
        variant={['narrow']}
      >
        <div
          className={combine(
            styles.factContainer,
            flex.row,
            flex.jcsa,
            styles.colMobile
          )}
        >
          <div className={combine(flex.col, flex.aic, styles.factWrapper)}>
            <img
              className={styles.factIcon}
              src={screens}
              alt="Digital screens icon"
            />
            <h3 className={styles.factFigure}>1300</h3>
            <p className={combine(styles.factDetail, txt.grey)}>
              Devices installed
            </p>
          </div>
          <div className={combine(flex.col, flex.aic, styles.factWrapper)}>
            <img className={styles.factIcon} src={time} alt="Egg timer icon" />
            <h3 className={styles.factFigure}>9 Months</h3>
            <p className={combine(styles.factDetail, txt.grey)}>
              Total timeline
            </p>
          </div>
          <div className={combine(flex.col, flex.aic, styles.factWrapper)}>
            <img className={styles.factIcon} src={geography} alt="Globe icon" />
            <h3 className={styles.factFigure}>20</h3>
            <p className={combine(styles.factDetail, txt.grey)}>
              Markets covered
            </p>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default VodafoneCaseStudy;

export const pageQuery = graphql`
  query vodafoneAssets {
    topImages: allImageSharp(
      filter: { fluid: { originalName: { regex: "/vodafone[1-3][.]jpg/" } } }
    ) {
      edges {
        node {
          fixed(width: 375, quality: 85) {
            name: originalName
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
    bigImage: imageSharp(
      fluid: { originalName: { regex: "/vodafone4[.]jpg/" } }
    ) {
      fluid(maxWidth: 1120, quality: 85) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    heroImage: imageSharp(
      fluid: { originalName: { regex: "/casestudy-heroImage.png/" } }
    ) {
      fluid(maxWidth: 1330, traceSVG: { color: "#ffffff" }) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
