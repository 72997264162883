import React from 'react';

import styles from './QuoteBox.module.scss';
import { bg, txt, flex, border, combine } from '../../style/utils';

import quoteIcon from '../../../assets/icons/quotation.png';

const QuoteBox = () => (
  <div>
    <div
      className={combine(
        styles.quoteContainer,
        txt.darkGrey,
        bg.white,
        border.footerHeaderGrey
      )}
    >
      <img className={styles.quoteIcon} src={quoteIcon} alt="Quote icon" />
      <p className={combine(txt.darkGrey, txt.light, styles.quoteText)}>
        Flare has such a user-friendly interface that lets you easily create
        great-looking content - we really have gotten our employees' attention
        with some key campaigns that we have run recently and it has increased
        staff engagement greatly!
      </p>
    </div>
    <div className={styles.attribution}>
      <p className={combine(txt.uppercase, txt.purple, styles.author)}>
        Liz Nelson
      </p>
      <p className={combine(styles.company, txt.italic, txt.grey, txt.normal)}>
        Vodafone Group
      </p>
    </div>
  </div>
);

export default QuoteBox;
